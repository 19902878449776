import { css } from 'styled-components'
import theme from './theme'

const { breakpoints } = theme.grid

const up = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

const down = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${(breakpoints[label] - 1) / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

const between = (min, max) => (...args) => css`
  @media (min-width: ${min / 16}em) and (max-width: ${(max - 1) / 16}em) {
    ${css(...args)}
  }
`

export default {
  up,
  down,
  between,
  breakpoints,
}
