import { createGlobalStyle } from 'styled-components'
import { DefaultStyling } from './Input'
import datePickerIcon from '../images/arrowUpDown.svg'

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Bulldog';
    src: url('/fonts/bulldogstd-webfont.woff2') format('woff2'),
        url('/fonts/bulldogstd-webfont.woff') format('woff'),
        url('/fonts/bulldogstd-webfont.ttf') format('ttf');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Bulldog';
    src: url('/fonts/bulldmedstd-webfont.woff2') format('woff2'),
        url('/fonts/bulldmedstd-webfont.woff') format('woff'),
        url('/fonts/bulldmedstd-webfont.ttf') format('ttf');
    font-weight: 700;
  }


  @font-face{
        font-family: "FFClan";
        src:url("/fonts/487435bb-c95d-4202-bf68-b10ac86caacc.eot?#iefix");
        src:url("/fonts/487435bb-c95d-4202-bf68-b10ac86caacc.eot?#iefix") format("eot"),url("/fonts/abe710ba-a0cb-4b37-b42f-d2a5265c1714.woff2") format("woff2"),url("/fonts/4fb3a5df-f261-4908-845c-dfd769d600f3.woff") format("woff"),url("/fonts/39c0d73c-c9da-495a-8afc-53df8e0f9a0d.ttf") format("truetype"),url("/fonts/f3752cdc-1577-4818-abec-cc3e2a94b227.svg#f3752cdc-1577-4818-abec-cc3e2a94b227") format("svg");
        font-weight: bold;
        font-style: normal;
    }
  @font-face{
        font-family: "FFClan";
        src:url("/fonts/ffclan-book.ttf");
        font-weight: normal;
        font-style: normal;
    }

  @-ms-viewport {
    width: device-width;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -ms-overflow-style: scrollbar;

    // Smaller than iPhone 6
    @media (max-width: 374.98px) {
      font-size: 2.666666667vw;
    }
  }

  body {
   padding: 0;
   margin: 0;
   font-family: ${props => props.theme.fontFamily};
   font-size: 1.6rem;
   color: ${props => props.theme.colors.grayDark};
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  h1, h2,h3,h4,h5,h6 {
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px ${props =>
      props.theme.colors.light} inset;
  }

  .Notification {
    font-family: ${props => props.theme.fontFamily};
    color: ${props => props.theme.colors.primary};
    border-radius: 3px;
    font-weight: 900;
    padding-left: 2rem;
    &--info {
      background: ${props => props.theme.colors.secondary};
    }
    &--success {
      background: ${props => props.theme.colors.secondary};
    }
    &--error {
      background: ${props => props.theme.colors.highlight};
      color: ${props => props.theme.colors.light};
    }
  }

  .Tooltip {
    background: ${props => props.theme.colors.primary}!important;
    &.place-top {
      &:after {
        border-top-color: ${props => props.theme.colors.primary} !important;
        border-top-style: solid !important;
      }
    }
  }


  /* GEO CODER */
  .mapboxgl-ctrl-geocoder {
    box-shadow: none!important;
    width: 100%!important;
    max-width: 100%!important;
    font-size: 2.1rem!important;
    background: #FFFFFF!important;
    border: 1px solid #CCCBCB!important;
    border-radius: 0!important;
    appearance: none!important;
    padding: 0 !important;
    color: #666666!important;
    letter-spacing: 0!important;
    line-height: 26px!important;
  }
  .mapboxgl-ctrl-geocoder .geocoder-icon-search {
    display: none;
  }
  .mapboxgl-ctrl-geocoder input[type='text'] {
    appearance: none !important;
  outline: none !important;
  background: #ffffff !important;
  padding: 0 1.6rem !important;
  min-height: 5.6rem !important;
  border: 1px solid #cccbcb !important;
  border-radius: 0 !important;
  font-family: ${props => props.theme.fontFamily} !important;
  font-size: 1.6rem !important;
  font-weight: 700 !important;
  color: ${props => props.theme.colors.darkGray} !important;
  letter-spacing: 0 !important;
  line-height: 2.6rem !important;
  width: 100% !important;
  transition: border 0.4s !important;
  &::placeholder {
    font-family: ${props => props.theme.fontFamily} !important;
    font-size: 1.6rem !important;
    font-weight: normal !important;
    color: ${props => props.theme.colors.darkGray} !important;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    font-family: ${props => props.theme.fontFamily} !important;
    font-size: 1.6rem !important;
    color: black !important;
    letter-spacing: 0 !important;
    line-height: 2.6rem !important;
    width: 100% !important;
  }
  -moz-appearance: textfield !important;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none !important;
    margin: 0 !important;
  }
  ${props =>
    props.error &&
    css`
      border: 2px solid ${props.theme.colors.error} !important;
    `}
  }
  .mapboxgl-ctrl-geocoder ul > li.active > a {
    background: ${props => props.theme.colors.primary}!important;
  }
  .mapboxgl-ctrl-geocoder ul > li > a {
    padding: 5px 1.6rem!important;
    font-size: 1.8rem!important;
    line-height: 2.4rem;
  }
  .mapboxgl-ctrl-geocoder {
    font-family: ${props => props.theme.fontFamily}!important;

  }

  .flickity-viewport {
    transition: height 0.2s;
  }

  .flickity-enabled{
    outline: none;
  }

  // DATEPICKER
  .react-datepicker-popper {
    z-index: 3!important;
  }

  .react-datepicker-wrapper {
    display: block!important;
  }
  .react-datepicker {
    font-family: ${props => props.theme.fontFamily}!important;
    font-weight: bold;
    box-shadow: 0px 1px 6px 0px rgba(51,51,51,0.4);
    border-radius: 0!important;
    border: 0!important;
    padding: 0!important;
    padding: 1.6rem 2.8rem!important;
    width: 34.5rem;
    display: block;
    &__input-container{
      display: block!important;
    }
    &__current-month {
      display: none!important;
    }
    &__navigation {
      display: none!important;
    }
    &__triangle {
      display: none;
    }
    &__header {
      background-color: white!important;
      border: 0!important;
      width: 100%;
      display: block;
      &__dropdown {
        display: flex;
      }
    }
      &__day-names {
        font-size: 1.2rem;
        margin-top: 0.8rem;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        
      }
      &__day-name {
        margin: 0!important;
        width: 4rem!important;
        height: 4rem;
        display: flex!important;
        justify-content: center;
        align-items: center;
        color: #CCCBCB!important;
      }
      &__month {
        margin: 0!important;
        font-weight: normal;
        border-style: solid;
        border-color: #CCCCCC;
        border-width: 1px 0 0 1px;
      }
      &__week {
        font-size: 1.6rem;
        display: flex;
        justify-content: space-between;
      }
      &__day {
        margin: 0!important;
        width: 4rem!important;
        height: 4rem;
        display: flex!important;
        justify-content: center;
        align-items: center;
        border-style: solid;
        border-color: #CCCCCC;
        border-width: 0 1px 1px 0;
        border-radius: 0!important;
        &--today {
          color: black !important;
          background-color: ${props => props.theme.colors.secondary}!important;
        }
        &--selected {
          background-color:  ${props => props.theme.colors.primary}!important;
        }
      }
    &__month-container {
      float: none;
    }
    &__month-dropdown-container {
      display: block!important;
      width: 100%;
      max-width: 100%;
      margin: 0!important;
    }
    &__year-dropdown-container {
      display: block!important;
      max-width: 100%;
      width: 100%;
      margin: 0!important;
      margin-left: 1.6rem!important;
      width: 58.5%;
    }
    &__month-select {
      ${DefaultStyling}
      background-image: url(${datePickerIcon});
      background-position: right 2rem top 50%, 0 0;
      background-repeat: no-repeat;
      min-height: 4rem;
    }
    &__year-select {
      ${DefaultStyling}
      background-image: url(${datePickerIcon});
      background-position: right 2rem top 50%, 0 0;
      background-repeat: no-repeat;
      min-height: 4rem;
    }
    &__month-container {
      width: 100%;
    }
  }

`

export default GlobalStyle
