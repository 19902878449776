/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { GridThemeProvider } from 'styled-bootstrap-grid'
import theme from './src/styles/theme'
import GlobalStyle from './src/components/GlobalStyle'

const Tealium = ({ children }) => {
  useEffect(() => {
    window.utag_data = {
      site_family: 'landing_page',
      site_country: 'DE',
      site_language: 'de',
      site_responsive_type: 'desktop',
      site_env_type: 'prod',
      page_name: 'schuldenfrei_tag_interhyp',
      page_type: 'landing_page',
      page_level_1: 'landingpage',
      page_level_2: 'schuldenfrei_tag_interhyp',
      user_login_status: 'logged out',
    }

    let a = '//tags.tiqcdn.com/utag/interhyp/int-de/prod/utag.js'
    const b = document
    const c = 'script'
    const d = b.createElement(c)
    d.src = a
    d.type = `text/java${c}`
    d.async = true
    a = b.getElementsByTagName(c)[0]
    a.parentNode.insertBefore(d, a)

    const script = document.createElement('script')
    script.src = 'https://cdn.optimizely.com/public/8135537693/s/interhyp.js'
    document.body.appendChild(script)

    const cachebuster = Date.now()

    // WEBSITE BASE COMPONENTS
    const websiteBaseComponentsScript = document.createElement('script')
    websiteBaseComponentsScript.src = `/angular/interhyp/website-base-components/build/ihg-website-base-components.esm.js?${cachebuster}`
    websiteBaseComponentsScript.setAttribute('type', 'module')

    const websiteBaseComponentsLegacyScript = document.createElement('script')
    websiteBaseComponentsLegacyScript.src = `/angular/interhyp/website-base-components/build/ihg-website-base-components.js?${cachebuster}`
    websiteBaseComponentsLegacyScript.setAttribute('nomodule', '')

    document.head.appendChild(websiteBaseComponentsScript)
    document.head.appendChild(websiteBaseComponentsLegacyScript)

    // CONSENT MANAGER
    const consentManagerScript = document.createElement('script')
    consentManagerScript.src = `/angular/interhyp/consent-manager/build/ihg-consent-manager.esm.js?${cachebuster}`
    consentManagerScript.setAttribute('type', 'module')

    const consentManagerLegacyScript = document.createElement('script')
    consentManagerLegacyScript.src = `/angular/interhyp/consent-manager/build/ihg-consent-manager.js?${cachebuster}`
    consentManagerLegacyScript.setAttribute('nomodule', '')

    document.head.appendChild(consentManagerScript)
    document.head.appendChild(consentManagerLegacyScript)
  }, [])
  return <>{children}</>
}

export const wrapRootElement = ({ element }) => (
  <Tealium>
    <ThemeProvider theme={theme}>
      <GridThemeProvider gridTheme={theme.grid}>
        <>
          {element}
          <GlobalStyle />
          <ihg-consent-manager imprint-link="https://www.interhyp.de/impressum"></ihg-consent-manager>
        </>
      </GridThemeProvider>
    </ThemeProvider>
  </Tealium>
)

// Number.isFinite =
//   Number.isFinite ||
//   function(value) {
//     return typeof value === 'number' && isFinite(value)
//   }
