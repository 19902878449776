const spaces = [
  '0',
  '.8rem',
  '1.6rem',
  '2.4rem',
  '3.2rem',
  '4rem',
  '4.8rem',
  '5.6rem',
  '6.4rem',
  '7.2rem',
  '8rem',
]

const breakpoints = {
  xxs: 0,
  xs: 375,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1400,
}

export default {
  grid: {
    breakpoints,
    col: {
      padding: 15,
    },
    row: {
      padding: 15,
    },
    container: {
      padding: 15,
      maxWidth: {
        xl: 1140,
        lg: 960,
        md: 720,
        sm: 540,
        xs: 400,
      },
    },
  },
  colors: {
    primary: '#EE7900',
    secondary: '#CDE2E8',
    tertiary: '#7699A3',
    tertiaryLight: '#E4F2F4',
    highlight: '#EE7900',
    grayDark: '#333333',
    error: '#CC0000',
    success: '#007520',
    background: '#F7F6F4',
  },
  fontFamily:
    'FFClan, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  fontFamilySecondary:
    'Bulldog, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  zIndex: {
    nav: 2000,
    sidebar: 1000,
  },
  space: spaces,
  breakpoints,
}
