/* eslint-disable no-nested-ternary */
import React, { Children, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker'
import { format } from 'date-fns'
import de from 'date-fns/locale/de' // register it with the name you want
import Select from 'react-select'
import ArrowDown from '../../images/arrowdown1.svg'
import Checkmark from '../../images/checkmark1.svg'
import DatePickerIcon from '../../images/datepicker.svg'
import SliderHandle from '../../images/sliderhandle.svg'
import Label from '../Label'
import theme from '../../styles/theme'

import 'react-datepicker/dist/react-datepicker.css'
// the locale you want
registerLocale('de', de)

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const DefaultStyling = css`
  margin: 0;
  padding: 0;
  appearance: none;
  outline: none;
  background: #ffffff;
  padding: 0 1.6rem;
  min-height: 5.6rem;
  border: 1px solid #cccbcb;
  border-radius: 0;
  font-family: ${props => props.theme.fontFamily};
  font-size: 1.6rem;
  font-weight: 700;
  color: ${props => props.theme.colors.darkGray};
  letter-spacing: 0;
  line-height: 2.6rem;
  width: 100%;
  transition: border 0.4s;
  &::placeholder {
    font-family: ${props => props.theme.fontFamily};
    font-size: 1.6rem;
    font-weight: normal;
    color: ${props => props.theme.colors.darkGray};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    font-family: ${props => props.theme.fontFamily};
    font-size: 1.6rem;
    color: black;
    letter-spacing: 0;
    line-height: 2.6rem;
    width: 100%;
  }
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
  ${props =>
    props.error &&
    css`
      border: 2px solid ${props.theme.colors.error};
    `}
`

const StyledInput = styled.input`
  ${DefaultStyling}
  ${props =>
    props.success &&
    css`
      background-image: url(${Checkmark});
      background-position: right 2rem top 50%, 0 0;
      background-repeat: no-repeat;
    `}
`

const StyledSelect = styled(Select)`
  .Select {
    &__control {
      ${DefaultStyling};
      &:hover {
        border: 1px solid #cccbcb;
      }
      &--is-focused {
        box-shadow: none;
      }
      background-image: url(${props =>
        props.success ? Checkmark : ArrowDown});
      background-position: right 2rem top 50%, 0 0;
      background-repeat: no-repeat;
    }
    &__indicators {
      display: none;
    }
    &__menu {
      z-index: 100;
      justify-content: flex-start;
      margin: 0;
      box-shadow: 3px 3px 5px 0px rgba(216, 216, 216, 1);
      /* background: #eeeeee; */
      background: #ffffff;
    }
    &__menu-list {
      padding: 0;
    }
    &__value-container {
      padding: 0;
    }
    &__single-value {
      margin: 0;
    }
    &__input {
      input {
        font-weight: 700;
      }
    }
  }
`

const StyledDatePicker = styled(DatePicker)`
  ${DefaultStyling}
`

const StyledWrapper = styled.div`
  display: block;
  z-index: 2;
`

const Error = styled.div`
  margin-top: 0.8rem;
  padding-left: 1.6rem;
  color: ${props => props.theme.colors.error};
`

const Range = styled.input`
  appearance: none;
  width: 100%;
  height: 1.6rem;
  background-color: #dddddd;
  border-radius: 8px;
  outline: none;
  &::-webkit-slider-thumb {
    appearance: none;
    width: 4rem;
    height: 4rem;
    background: url(${SliderHandle});
    cursor: pointer;
  }
`

const DatePickerButton = styled.input`
  ${DefaultStyling}
  display: flex;
  background-image: ${props =>
    props.success ? `url(${Checkmark})` : `url(${DatePickerIcon})`};
  background-position: right 1.7rem top 50%, 0 0;
  background-repeat: no-repeat;
`

const DatePickerComponent = ({ value, onClick, error }) => (
  <DatePickerButton
    error={error}
    type="button"
    onClick={onClick}
    value={value}
  />
)

const selectStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isFocused ? '#eeeeee' : '#ffffff',
    height: '5.6rem',
    borderLeft: isFocused && `4px solid ${theme.colors.primary}`,
    padding: '0 1.6rem',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: isFocused && '1.2rem',
    color: 'black',
    margin: 0,
  }),
  menu: styles => ({ ...styles, margin: '0', padding: '0' }),
}

const Input = ({
  type,
  value,
  placeholder,
  name,
  label,
  success,
  error,
  children,
  options,
  onChange,
  disabled,
  onBlur,
  onFocus,
  labelTriggered,
  onClick,
  maxDate,
  minDate,
}) => {
  const [startDate, setStartDate] = useState(null)
  return (
    <Wrapper>
      {type === 'select' ? (
        <StyledSelect
          disabled={disabled}
          value={value}
          onChange={onChange}
          success={success}
          name={name}
          placeholder={placeholder}
          classNamePrefix="Select"
          options={options}
          styles={selectStyles}
          onClick={onClick}
          onFocus={onFocus}
          autoComplete="off"
          error={error}
        >
          {children}
        </StyledSelect>
      ) : type === 'date' ? (
        <StyledWrapper>
          <StyledDatePicker
            disabled={disabled}
            selected={startDate}
            onChange={value => {
              console.log('test')
              onChange(format(value, 'dd.MM.yyyy'))
            }}
            type={type}
            value={value}
            name={name}
            placeholderText={placeholder}
            success={success}
            error={error}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat="dd.MM.yyyy"
            locale="de"
            onClick={onClick}
            onFocus={onFocus}
            autoComplete="off"
            error={error}
            customInput={<DatePickerComponent error={error} />}
            maxDate={maxDate}
            minDate={minDate}
          />
        </StyledWrapper>
      ) : type === 'range' ? (
        <Range type="range" />
      ) : (
        <StyledInput
          disabled={disabled}
          type={type}
          value={value === undefined ? '' : value}
          name={name}
          placeholder={placeholder}
          success={success}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onClick={onClick}
          autoComplete="off"
        />
      )}
      <Label top={labelTriggered} error={error}>
        {label}
      </Label>
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
}

export default Input

export { DefaultStyling }

export const FinalFormInput = ({
  input,
  meta,
  disabled,
  dirtySinceLastSubmit,
  ...rest
}) => (
  <Input
    disabled={disabled}
    {...input}
    error={
      (meta.touched && meta.error) ||
      (!meta.dirtySinceLastSubmit && !dirtySinceLastSubmit && meta.submitError)
    }
    success={meta.modified && input.value && meta.valid}
    labelTriggered={meta.active || input.value}
    {...rest}
  />
)
