import styled, { css } from 'styled-components'
import media from '../../styles/media'

const Label = styled.span`
  max-width: calc(100% - 37px);
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: ${props => props.theme.fontFamily};
  color: ${props =>
    props.error ? props.theme.colors.error : props.theme.colors.darkGray};
  top: ${props => (props.top ? '0' : '1.9rem')};
  left: ${props => (props.top ? '1.2rem' : '1.9rem')};
  pointer-events: none;
  transition: 0.15s all;
  transform: ${props => (props.top ? 'translateY(calc(-50% + 0.1rem))' : '0')};
  position: absolute;
  padding: ${props => (props.top ? '0 0.8rem' : '')};
  font-size: ${props => props.top && '1.2rem'};
  z-index: 1;
  overflow: hidden;
  ${media.down.sm`
    font-size: 1.4rem;
  `}
  &:after {
    content: '';
    position: absolute;
    height: ${props => (props.top ? '3px' : '100%')};
    width: 100%;
    height: 0.3rem;
    background-color: white;
    top: ${props => (props.top ? '49%' : '0.3rem')};
    transform: translateY(-50%);
    left: 0;
    z-index: -1;
    transition: 0.15s top, 0.15s height;
    transition-delay: 0.05s;
  }
`

export default Label
